.table-outline {
  border: 1px solid $table-border-color;

  td {
    vertical-align: middle;
  }
}

.table-align-middle {

  td {
    vertical-align: middle;
  }
}

.table-clear {
  td {
    border: 0;
  }
}

.table-responsive {
  @include media-breakpoint-down(md) {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

    // Prevent double border on horizontal scroll due to use of `display: block;`
    &.table-bordered {
      border: 0;
    }
  }
}
