// Here you can add other styles
.navbar {
  .form-inline {
    i {
      color: $navbar-color;
    }
    .form-control {
      margin-top: -3px;
      color: $navbar-color;
      border: 0;

      &::placeholder {
        color: $navbar-color;
      }
    }
    .form-control::-webkit-input-placeholder { color: $navbar-color; }
    .form-control:-moz-placeholder { color: $navbar-color; }
    .form-control::-moz-placeholder { color: $navbar-color; }
    .form-control:-ms-input-placeholder { color: $navbar-color; }
  }
  .nav-item {

    .nav-link.nav-pill {
      width: 32px;
      height: 32px;
      padding: 0;
      line-height: 32px;
      border: 1px solid $navbar-color;
      border-radius: 50em;

      &:hover {
        border-color: $navbar-hover-color;
      }

      .badge-pill {
        margin-top: -18px;
        margin-left: -5px;
        border: 2px solid #fff !important;
        line-height: 14px;
      }

      &.avatar {
        width: 38px;
        height: 38px;

        img {
          width: 36px;
          height: 36px;
          margin: 0;
          border: 0;
        }

        .badge {
          margin-top: -22px;
          margin-left: 5px;
        }

        &.dropdown-toggle:after {
          display: none;
        }
      }
    }
  }
}


.sidebar .sidebar-nav .nav {
  .nav-title {
    span {
      position: relative;
      display: inline-block;

      &:before, &:after {
        position: absolute;
        top: 50%;
        width: 25px;
        height: 1px;
        margin-top: -1px;
        content: '';
        border-bottom: 1px solid $sidebar-nav-title-color;
      }

      &:before {
        left: -30px;
      }

      &:after {
        right: -30px;
      }
    }
  }
}

#account-nav-link {
  border: 0;
}

.list-without-decoration {
  list-style: none;
  padding-left: 0;
}

main {
  display: flex;
  flex-direction: column;
}

main > .main-content {
  flex: 1;
}

main > .container-fluid {
  width: 100%;
}

.main-content > div {
  flex: 1;
}

#root {
  flex: 1;
}

.left-aligned {
  text-align: left;
}

.center-aligned {
  text-align: center;
}

.right-aligned {
  text-align: right;
}

.nowrap {
  white-space: nowrap;
}

.subnavigation-links span, .subnavigation-links a {
  color: #444;
}

.overflowed-table-cell {
  text-overflow: ellipsis;
  max-width: 0;
  overflow: hidden;
  white-space: nowrap;
}

.flex-grow {
  flex-grow: 1;
}

.toastify {
  margin-top: 60px !important;
}

.api-form-filters {
  margin-top: -8px;
}

.api-form-filters > div {
  margin-top: 8px;
}

.page-item.active .page-link, .pagination-datatables li.active .page-link, .pagination li.active .page-link, .page-item.active .pagination-datatables li a, .pagination-datatables li .page-item.active a, .pagination-datatables li.active a, .page-item.active .pagination li a, .pagination li .page-item.active a, .pagination li.active a {
  z-index: 0;
}

.list-without-decoration {
  list-style-type: none;
  padding-left: 0;
}

.Select * {
  z-index: 100;
}

.Select-option, .Select-menu-outer {
  z-index: 101 !important;
}

.Select-option {
  color: #666666 !important;
}

#entity_association_card .form-control {
  color: #374767;
}

.bg-green a {
  color: #28a745 !important;
  //font-weight: bold;
}

.css-mueken, [data-css-mueken] {
    top: 5em !important;
    right: 1em;
}

