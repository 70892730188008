// Bootstrap overrides

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark:                 #384042;
$gray:                      #9faecb;
$gray-light:                #c0cadd;
$gray-lighter:              #e1e6ef;
$gray-lightest:             #f9f9fa;

$brand-primary:             #20a8d8;
$brand-success:             #79c447;
$brand-info:                #67c2ef;
$brand-warning:             #fabb3d;
$brand-danger:              #ff5454;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:        true;
$enable-rounded:            false;

// Body
//
// Settings for the `<body>` element.

$body-bg:                    #f2f4f8;
$body-color:                 #374767;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:             0.875rem;

// Breadcrumbs

$breadcrumb-padding-x:          2rem !default;
$breadcrumb-bg:                 transparent;

// Cards

$card-border-color:        $gray-lighter;
$card-cap-bg:              $gray-lightest;

// Dropdowns

$dropdown-padding-y:             0;
$dropdown-border-color:          $gray-lighter;
$dropdown-divider-bg:            $gray-lightest;

// Progress bars

$progress-bg:                    darken($gray-lightest,1%);

// Forms

$input-border-color:             $gray-lighter;
